import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Nav from "./nav";
import Seo from "./seo";

// var path = require(`path`); // s4n
import { useIntl, Link, FormattedHTMLMessage } from 'gatsby-plugin-intl' // s4n

const Layout = ({ children, seo }) => {

  const intl = useIntl();
  const t = intl.formatMessage;
  // const langPrefix = `soft4net_` + __filename.replace(`.js`, ``).replaceAll(`/`, `_`); // === `src_components_nav`

  return (
    <StaticQuery
      query={graphql`
        query {
          strapiHomepage {
            seo {
              metaTitle
              metaDescription
              shareImage {
                publicURL
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Seo seo={seo} />
          <Nav />



          {/* <!-- navigation --> */}
          {/* <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
            <button tabIndex="0" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label={t({ id: `soft4net_toggle_navigation` })}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <Menu type="main" injectContentToMenuId={injectContentToMenuId} />
            </div>
          </nav> */}
          {/* <!-- /navigation --> */}



          <main>         

            {/* <div className="uk-container uk-container-small"></div> */}

{/* <div class="uk-child-width-expand@s uk-text-center" uk-grid>
    <div>
        <div class="uk-card uk-card-default uk-card-body">Item</div>
    </div>
    <div>
        <div class="uk-card uk-card-default uk-card-body">Item</div>
    </div>
    <div>
        <div class="uk-card uk-card-default uk-card-body">Item</div>
    </div>
</div> */}

            <div class="uk-container uk-container-expand">
              <div className="uk-grid" data-uk-grid-margin uk-grid>
                <div className="_uk-width-1-3@m uk-width-1-3@l uk-width-1-4@xl uk-row-first">
                  <ul class="uk-nav-primary uk-nav-parent-icon uk-nav" uk-nav>
                      <li class="uk-active"><Link to="/">Home</Link></li>
                      <li class="uk-parent">
                          <Link to={`/pozwolenie-na-bron`}>Pozwolenie na broń</Link>
                          <ul class="uk-nav-sub">
                              <li><Link to={`/pozwolenie-na-bron/galeria-broni`}>Galeria broni</Link></li>
                              <li><Link to={`/pozwolenie-na-bron/bron-do-celow-sportowych`}>Broń do celów sportowych</Link></li>
                              <li><Link to={`/pozwolenie-na-bron/bron-do-celow-kolekcjonerskich`}>Broń do celów kolekcjonerskich</Link></li>
                              <li><Link to={`/pozwolenie-na-bron/bron-do-celow-szkoleniowych`}>Broń do celów szkoleniowych</Link></li>
                              <li><Link to={`/pozwolenie-na-bron/dopuszczenie-do-posiadania-broni`}>Dopuszczenie do posiadania broni</Link></li>
                              <li><Link to={`/pozwolenie-na-bron/sebastian-predel-kontakt`}>Sebastian Predel Kontakt</Link></li>
                          </ul>
                      </li>
                      {/* <li><Link to={`/pozwolenie-na-bron`}>Pozwolenie na broń</Link></li> */}
                      <li><Link to={`/szkolenia-strzeleckie`}>Szkolenia strzeleckie</Link></li>
                      <li><Link to={`/galeria`}>Galeria</Link></li>
                      <li><Link to={`/o-mnie`}>O mnie</Link></li>
                      <li><Link to={`/kontakt`}>Kontakt</Link></li>
                  </ul>
                </div>
                <div className="_uk-width-2-3@m uk-width-2-3@l uk-width-1-2@xl">
                  {children}

                  <footer 
                    // className="uk-container uk-container-small"
                  >
                    <div className={`uk-grid uk-child-width-expand@s`}>
                      <div>
                        <FormattedHTMLMessage 
                          id={`soft4net_copyright`}
                          values={{
                            currentYear: new Date().getFullYear(),
                          }}
                        />{` `}{ t({ id: `soft4net_all_rights_reserved` }) }
                      </div>
                      <div className={`uk-text-right`}>
                        <a href="" title={`Facebook`} target="_blank" uk-icon="facebook"></a>
                        <a href="" title={`Instagram`} target="_blank" uk-icon="instagram"></a>
                        <a href="" title={`Vimeo`} target="_blank" uk-icon="vimeo"></a>
                      </div>
                    </div>

                    <p className="madeby uk-text-center">
                          <a 
                              href="https://www.soft4net.pl/" 
                              title={ t({ id: `soft4net_project_realization_anchor_title` }) } 
                              target="_blank"
                              rel="noopener"
                          >
                              { t({ id: `soft4net_project_realization` }) } 
                          </a>
                      </p>
                      {/* {`true` === process.env.SOFT4NET_DISPLAY_BUILD_TIME_IN_FOOTER && 
                          <p className="mt-5">
                              { t({ id: "soft4net_deploy_build_time" }) }: {GeneralUtils.dateFormat(buildTime, "LLL", intl.locale)}
                          </p>
                      } */}
                  </footer>

                </div>
                {/* <div className="uk-width-2-3@m uk-width-2-3@l uk-width-1-4@xl"></div> */}
              </div>
            </div>

          </main>

        </>
      )}
    />
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
