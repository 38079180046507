import React from "react";
import { 
  // Link, 
  StaticQuery, graphql } from "gatsby";

// s4n start
// var path = require(`path`);
import { useIntl, Link } from 'gatsby-plugin-intl'
import Logo from './../images/logo.png'
// import Logo from './../images/custom/logo.svg'
// s4n stop


const Nav = () => {

  const intl = useIntl();
  const t = intl.formatMessage;
  // const langPrefix = `soft4net_` + __filename.replace(`.js`, ``).replaceAll(`/`, `_`); // === `src_components_nav`

  return (
    <StaticQuery
      query={graphql`
        query {
          strapiGlobal {
            siteName
          }
          allStrapiCategory {
            edges {
              node {
                slug
                name
              }
            }
          }
        }
      `}
      render={(data) => (
        <div class="uk-container uk-container-expand">
          <div>
            <nav className="uk-navbar-container" data-uk-navbar>
              <div className="uk-navbar-left">
                <ul className="uk-navbar-nav">
                  <li>

{/* <Link to="/">{data.strapiGlobal.siteName}</Link> */}
<Link 
  to="/"
  className="link-logo"
  // {...linkLogoProps}
  title={data.strapiGlobal.siteName} 
>
  <img src={Logo} alt={data.strapiGlobal.siteName} />
  {/* <span>{data.strapiGlobal.siteName}</span> */}
</Link>

                  </li>
                </ul>
              </div>
              <div className="uk-navbar-right">
                <button
                  className="uk-button uk-button-default uk-margin-right"
                  type="button"
                >
{/* { t({ id: `${langPrefix}_categories` }) } */}
{ t({ id: `soft4net_src_components_nav_categories` }) }
                </button>
                <div uk-dropdown="animation: uk-animation-slide-top-small; duration: 1000">
                  <ul className="uk-nav uk-dropdown-nav">
                    {data.allStrapiCategory.edges.map((category, i) => (
                      <li key={`category__${category.node.slug}`}>
                        <Link to={`/category/${category.node.slug}`}>
                          {category.node.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      )}
    />
)};

export default Nav;
